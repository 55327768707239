export const environment = {
  production: false,
  mapsKey: 'AIzaSyCZ6C5bxn8SfyKO7IatjhIAwnESkFs8VrQ',
  apiHostUrl: 'https://api.dev.restaapp.com',
  staticHostUrl: 'https://static.dev.restaapp.com',
  amplify: {
    Auth: {
      region: 'eu-west-1',
      identityPoolId: 'eu-west-1:0bd25893-31b0-407d-9c19-e4b60d05e7b1',
      userPoolId: 'eu-west-1_brveVs5og',
      userPoolWebClientId: '2ir1qfmltm5phmr0o5vvdm9apu',
      mandatorySignIn: false,
      oauth: {
        domain: 'dev-restaapp.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://dev.restaapp.com/',
        redirectSignOut: 'https://dev.restaapp.com/',
        responseType: 'code', // or 'token', depending on the auth flow
        options: {
          client_id: '791748679554-u1ug4gksomq0h5jkbbpasbtu6vkv9pa8.apps.googleusercontent.com',
          client_id_facebook: '1936580570172093',
        },
      },
    },
    Analytics: {
      disabled: false,
      autoSessionRecord: true,
      AWSPinpoint: {
        region: 'eu-west-1',
        appId: '9115b9ecf84c472d95cd65a956d5b580',
      },
    },
  },
};